import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@/assets/base.css";
import "@/assets/style.css";

import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import App from "@/App.vue";
import Button from "primevue/button";
import Card from "primevue/card";

import { createApp, Plugin } from "vue";
import { createPinia } from "pinia";

import router from "@/router";

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);

app.use(PrimeVue as unknown as Plugin, {
  // Default theme configuration
  theme: {
    preset: Aura,
    options: {
      prefix: "p",
      darkModeSelector: "system",
      cssLayer: false,
    },
  },
});

app.component("PCard", Card);
app.component("PButton", Button);

app.mount("#app");
