import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import { authRouteGuard } from "@wovar/common/auth/router.js";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomePage,
    },
  ],
});

router.beforeEach(authRouteGuard);

export default router;
